import React from 'react';

// Material-UI Components
import { Box, Typography, Button } from '@mui/material';
import { Shop } from '@mui/icons-material'; // Placeholder, replace with Play Store icon

// Localization
import { useTranslation } from 'react-i18next';

// Local styles
import useStyles from '../styles';

const Jumbotron: React.FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Box className={classes.jumbotron}>
            <Typography variant="h2" className={classes.jumbotronTitle}>
                {t('welcomeAliasGame')}
            </Typography>
            <Typography variant="h5" gutterBottom className={classes.jumbotronSubtitle}>
                {t('experienceAdventure')}
            </Typography>
            <Box className={classes.jumbotronButtonGroup}>
                <Button
                    variant="contained"
                    className={`${classes.jumbotronButton} ${classes.button}`}
                    size="large"
                    href="https://aliasai-dcb87.web.app/"
                >
                    {t('playGame')}
                </Button>
                <Button
                    variant="contained"
                    className={`${classes.jumbotronButton} ${classes.button}`}
                    size="large"
                    href="https://play.google.com/store/apps/details?id=com.serdun.alias_ai"
                    startIcon={<Shop />} // Replace Shop with the Play Store icon
                >
                    {t('playOnPlayStore')}
                </Button>
            </Box>
        </Box>
    );
};

export default Jumbotron;
