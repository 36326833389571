import React, {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import {Link} from 'react-router-dom';
import {useTheme} from '@mui/material/styles'; // Updated import
import useMediaQuery from '@mui/material/useMediaQuery';
import LanguageSwitcher from './LanguageSwitcher';
import {useTranslation} from 'react-i18next';
import useStyles from '../styles';

const Navbar: React.FC = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const theme = useTheme(); // Ensure using MUI theme
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open: boolean) => () => {
        setDrawerOpen(open);
    };

    const drawer = (
        <div className={classes.drawerList} role="presentation" onClick={toggleDrawer(false)}
             onKeyDown={toggleDrawer(false)}>
            <List>
                <ListItem component={Link} to="/">
                    <ListItemButton>
                        <ListItemText primary={t('navbarHome')}/>
                    </ListItemButton>
                </ListItem>
                <ListItem component={Link} to="/privacy">
                    <ListItemButton>
                        <ListItemText primary={t('navbarPrivacyPolicy')}/>
                    </ListItemButton>
                </ListItem>
                <ListItem component={Link} to="/terms">
                    <ListItemButton>
                        <ListItemText primary={t('navbarTermsAndConditions')}/>
                    </ListItemButton>
                </ListItem>
                <ListItem component={Link} to="/changelog">
                    <ListItemButton>
                        <ListItemText primary={t('navbarChangeLog')}/>
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <LanguageSwitcher/>
                </ListItem>
            </List>
        </div>
    );

    return (
        <AppBar position="static" sx={{backgroundColor: '#122223'}}>
            <Toolbar className={classes.toolbar}>
                <div className={classes.logo}>
                    <img src="/images/logo.png" alt={t('aliasGameLogo')} style={{height: '40px', marginRight: '10px'}}/>
                    <Typography variant="h6" color="inherit">
                        {t('aliasGame')}
                    </Typography>
                </div>
                {isMobile ? (<>
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleDrawer(true)}>
                            <MenuIcon/>
                        </IconButton>
                        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>{drawer}</Drawer>
                    </>
                ) : (
                    <div className={classes.navLinks}>
                        <Button color="inherit" component={Link} to="/" sx={{color: '#5CA8AB'}}>{t('home')}
                        </Button>
                        <Button color="inherit" component={Link} to="/privacy"
                                sx={{color: '#5CA8AB'}}>{t('privacyPolicy')}
                        </Button>
                        <Button color="inherit" component={Link} to="/terms"
                                sx={{color: '#5CA8AB'}}>{t('termsAndConditions')}</Button>
                        <Button color="inherit" component={Link} to="/changelog"
                                sx={{color: '#5CA8AB'}}>{t('navbarChangeLog')}</Button>
                        <LanguageSwitcher/>
                    </div>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
