import React from 'react';

// Material-UI Components
import {Typography, Button} from '@mui/material';
import AndroidIcon from '@mui/icons-material/Android';

// Localization
import {useTranslation} from 'react-i18next';

// Local styles
import useStyles from '../styles';

const DownloadSection: React.FC = () => {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <div id="download" className={classes.cardSection}>
            <Typography variant="h4" className={classes.title}>
                {t('downloadAliasGame')}
            </Typography>
            <Typography variant="body1" className={classes.subtitle}>
                {t('getAliasGameOnYourMobileDevice')}
            </Typography>
            <div className={classes.buttonContainer}>
                <Button
                    variant="contained"
                    className={classes.button}
                    href="https://play.google.com/store/apps/details?id=com.serdun.alias_ai">
                    <AndroidIcon className={classes.icon}/>
                    {t('getItOnGooglePlay')}
                </Button>
            </div>
        </div>
    );
};

export default DownloadSection;
