import React, {useEffect, useState} from 'react';
import {Container, Typography, Box, Card, CardContent} from '@mui/material';
import {useTranslation} from 'react-i18next';

import useStyles from '../styles';

interface ChangeLogEntry {
    version: string;
    date: string;
    changes: string[];
}

const ChangeLog: React.FC = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const [changelog, setChangelog] = useState<ChangeLogEntry[]>([]);

    useEffect(() => {
        fetch('/changelog.json')
            .then(response => response.json())
            .then((data: ChangeLogEntry[]) => setChangelog(data))
            .catch(error => console.error('Error fetching changelog:', error));
    }, []);

    return (
        <Box display="flex" flexDirection="column" minHeight="100vh">
            <Container>
                <Box mt={5} textAlign="center">
                    <Typography variant="h2">{t('changeLog')}</Typography>
                    <Typography variant="h5" gutterBottom>
                        {t('changeLogDescription')}
                    </Typography>
                </Box>
                <Box mt={5} flexGrow={1}>
                    <Container maxWidth="md">
                        {changelog.map((log, index) => (
                            <Card className={classes.card} key={index}>
                                <CardContent className={classes.cardBody}>
                                    <Typography variant="h4">{log.version} - {log.date}</Typography>
                                    <ul>
                                        {log.changes.map((change, idx) => (
                                            <li key={idx}>
                                                <Typography>{change}</Typography>
                                            </li>
                                        ))}
                                    </ul>
                                </CardContent>
                            </Card>
                        ))}
                    </Container>
                </Box>
            </Container>
        </Box>
    );
};

export default ChangeLog;