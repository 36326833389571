import React from 'react';

// Material-UI Components
import {Box, Typography, Divider} from '@mui/material';
import {CheckCircle} from '@mui/icons-material';

// Localization
import {useTranslation} from 'react-i18next';

// Local styles
import useStyles from '../styles';

const Features: React.FC = () => {
    const classes = useStyles();
    const {t} = useTranslation();

    const features = [
        t('gameFeatures'),
        t('diveIntoThemes'),
        t('formTeams'),
        t('guessFinalWord'),
        t('setTimeLimit'),
        t('userFriendlyEditor'),
        t('createMultipleSessions'),
    ];

    return (
        <Box id="features" className={classes.featureSection}>
            <Typography variant="h4" gutterBottom align="left" className={classes.cardTitle}>
                {features[0]}
            </Typography>
            <Divider sx={{marginY: 2, backgroundColor: '#5CA8AB'}}/>
            {features.slice(1).map((feature, index) => (
                <Box key={index} className={classes.featureItem}>
                    <CheckCircle color="primary" className={classes.featureIcon}/>
                    <Typography variant="body1" className={classes.cardText}>{feature}</Typography>
                </Box>
            ))}
        </Box>
    );
};

export default Features;
