import {makeStyles} from '@mui/styles';
import {Theme} from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
    jumbotron: {
        marginTop: '40px',
        padding: '40px',
        backgroundColor: '#f8f9fa',
        textAlign: 'center',
        backgroundImage: 'url("../images/hero.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        borderRadius: '12px',
        color: '#fff',
        boxShadow: theme.shadows[3],
        position: 'relative',
        '&:before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            borderRadius: '12px',
            zIndex: 0,
        },
        '& h2, & h5': {
            position: 'relative',
            zIndex: 1,
        },
    },
    jumbotronTitle: {
        fontWeight: 'bold',
        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
        color: '#FFFFFF',
    },
    jumbotronSubtitle: {
        marginTop: '1rem',
        fontSize: '1.5rem',
        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
        color: '#FFFFFF',
    },
    iconButton: {
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
        },
    },
    jumbotronButtonGroup: {
        display: 'flex',
        justifyContent: 'center',
        gap: theme.spacing(2), // Space between the buttons
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column', // Stack buttons vertically on small screens
            width: '100%',
        },
    },

    jumbotronButton: {
        backgroundColor: '#F18530',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#F18530',
            transform: 'scale(1.05)',
        },
        transition: 'background-color 0.3s, transform 0.3s',
    },
    section: {
        marginTop: '40px',
        textAlign: 'center',
    },
    carouselContainer: {
        display: 'flex',
        overflowX: 'auto',
        padding: theme.spacing(1),
        '&::-webkit-scrollbar': {
            height: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#ccc',
            borderRadius: '4px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#f0f0f0',
        },
    },
    screenshot: {
        flexShrink: 0,
        width: 'auto',
        height: 'auto',
        borderRadius: theme.spacing(0.5),
        boxShadow: theme.shadows[2],
        marginRight: theme.spacing(2),
        cursor: 'pointer',
        objectFit: 'contain',
        maxHeight: '300px',
    },
    carouselItem: {
        display: 'flex',
        justifyContent: 'center',
        gap: '10px',
    },
    modalImage: {
        maxWidth: '90%',
        maxHeight: '90%',
        cursor: 'pointer',
    },
    modalContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        position: 'relative',
    },
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        color: '#FF0000', // Red
    },
    cardSection: {
        margin: '40px 0',
        textAlign: 'center',
        background: `linear-gradient(320deg, #5CA8AB 75%, #F18530 30%, #ff6f00 100%)`,
        padding: '40px',
        borderRadius: '16px',
        boxShadow: theme.shadows[4],
    },
    title: {
        marginBottom: '20px',
        fontWeight: 'bold',
        fontSize: '2rem',
        color: theme.palette.primary.contrastText,
    },
    subtitle: {
        marginBottom: '30px',
        color: theme.palette.primary.contrastText,
        fontSize: '1.2rem',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '30px',
        gap: '20px',
    },
    button: {
        textTransform: 'none',
        padding: '12px 24px',
        borderRadius: '6px',
        boxShadow: theme.shadows[2],
        backgroundColor: '#F18530', // Orange (wheel)
        transition: 'background-color 0.3s, box-shadow 0.3s',
        '&:hover': {
            backgroundColor: '#d9670f', // Darker shade of Orange
            boxShadow: theme.shadows[4],
        },
    },
    icon: {
        marginRight: '8px',
    },
    card: {
        border: '1px solid #ddd',
        borderRadius: '8px',
        padding: '16px',
        boxShadow: theme.shadows[1],
        marginBottom: '20px',
    },
    cardBody: {
        padding: '16px',
    },
    cardTitle: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        marginBottom: '8px',
        color: '#5CA8AB', // Verdigris for title
    },
    cardText: {
        fontSize: '1rem',
        color: '#555',
    },
    featureSection: {
        marginTop: '40px',
        textAlign: 'left',
    },
    featureItem: {
        marginTop: '16px',
        display: 'flex',
        alignItems: 'center',
        marginY: '8px',
    },
    featureIcon: {
        marginRight: '8px',
    },
    footer: {
        backgroundColor: '#122223', // Dark Verdigris
        color: 'white',
        textAlign: 'center',
        justifyContent: 'center',
        padding: '20px 0',
        marginTop: '40px',
    },
    footerLink: {
        color: 'white',
        textDecoration: 'none',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    footerContent: {
        textAlign: 'center',
    },
    formControl: {
        minWidth: 120,
        margin: '0 10px',
    },
    select: {
        '& .MuiSelect-select': {
            padding: '10px 14px',
            color: '#5CA8AB', // Verdigris
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: '#5CA8AB', // Verdigris
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#488A8C', // Darker Verdigris on hover
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#488A8C', // Darker Verdigris on focus
        },
    },
    logo: {
        display: 'flex',
        alignItems: 'center',
    },
    navLinks: {
        display: 'flex',
        gap: '15px',
    },
    menuButton: {
        marginRight: '10px',
    },
    drawerList: {
        width: 250,
    },
    screenshotsSection: {
        marginTop: '40px',
        textAlign: 'left',
    },
    notFoundContainer: {
        marginTop: '5rem',
        textAlign: 'center',
    },
    emailLink: {
        color: 'white !important',
    },
    centerText: {
        textAlign: 'center',
    },

}));

export default useStyles;