import React from 'react';
import {Container, Typography, Box, Card, CardContent, Link} from '@mui/material';
import {useTranslation} from 'react-i18next';

import useStyles from '../styles';

const PrivacyPolicy: React.FC = () => {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <Container>
            <Box mt={5} textAlign="center">
                <Typography variant="h2">{t('privacyPolicy')}</Typography>
                <Typography variant="h5" gutterBottom>
                    {t('privacyImportant')}
                </Typography>
            </Box>
            <Box mt={5}>
                <Container maxWidth="md">
                    {[
                        {title: 'informationWeCollect', content: 'noPersonalInfo'},
                        {title: 'dataUsage', content: 'localDevice'},
                        {title: 'noDataSharing', content: 'noDataSharingInfo'},
                        {title: 'localStorage', content: 'localStorageInfo'},
                        {title: 'securityMeasures', content: 'securityMeasuresInfo'},
                        {title: 'updatesPrivacyPolicy', content: 'updatesPrivacyPolicyInfo'},
                        {title: 'privacyContactUs', content: 'contactUsInfo'},
                    ].map((item, index) => (
                        <Card className={classes.card} key={index}>
                            <CardContent className={classes.cardBody}>
                                <Typography variant="h4">{t(item.title)}</Typography>
                                <Typography>
                                    {item.title === 'privacyContactUs' ? (
                                        <>
                                            {t(item.content)}{' '}
                                            <Link href="mailto:fireserdun@gmail.com">fireserdun@gmail.com</Link>.
                                        </>
                                    ) : (
                                        t(item.content)
                                    )}
                                </Typography>
                            </CardContent>
                        </Card>
                    ))}
                </Container>
            </Box>
        </Container>
    );
}

export default PrivacyPolicy;
