import React from 'react';

// Material-UI Components
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

// Localization
import {useTranslation} from 'react-i18next';

// Local styles
import useStyles from '../styles';

const Footer: React.FC = () => {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <AppBar position="static" component="footer" sx={{backgroundColor: '#122223'}}>
            <div className={classes.footerContent}>
                <Typography variant="h6" color="inherit">{t('footerContactUs')}</Typography>
                <Typography variant="body1" className={classes.centerText} color="inherit">
                    {t('questionsConcerns')}{' '}
                    <Link href="mailto:fireserdun@gmail.com" className={`${classes.footerLink} ${classes.emailLink}`}>
                        {t('contactUsLink')}
                    </Link>
                </Typography>
                <Typography variant="body2" color="inherit">&copy; 2024 Alias
                    Game. {t('allRightsReserved')}</Typography>
            </div>
        </AppBar>
    );
};

export default Footer;
