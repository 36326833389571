import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

const version = '1.0.0'; // Update this version to bust cache

i18n
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        supportedLngs: ['en', 'es', 'uk'],
        fallbackLng: 'en',
        detection: {
            order: ['queryString', 'cookie'],
            caches: ['cookie']
        },
        backend: {
            loadPath: `/locales/{{lng}}/translation.json?v=${version}`
        }
    });

export default i18n;