import React, { useState, useMemo } from 'react';
import { Box, Modal, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

import useStyles from '../styles';

const Screenshots: React.FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [selectedImage, setSelectedImage] = useState<string | null>(null);

    const screenshots = useMemo(() => [
        "images/screenshot1.jpg",
        "images/screenshot2.jpg",
        "images/screenshot3.jpg",
        "images/screenshot4.jpg",
        "images/screenshot5.jpg",
        "images/screenshot6.jpg",
        "images/screenshot7.jpg",
        "images/screenshot8.jpg",
        "images/screenshot9.jpg",
    ], []);

    return (
        <Box id="screenshots" className={classes.screenshotsSection}>
            <Typography variant="h4" gutterBottom align="left" className={classes.cardTitle}>
                {t('screenshots')}
            </Typography>
            <div id="download" className={classes.cardSection}>
                <Box className={classes.carouselContainer}>
                    {screenshots.map((src, index) => (
                        <Box
                            key={index}
                            component="img"
                            src={src}
                            alt={`Screenshot ${index + 1}`}
                            className={classes.screenshot}
                            onClick={() => setSelectedImage(src)}
                            loading="lazy"
                        />
                    ))}
                </Box>
            </div>

            <Modal
                open={!!selectedImage}
                onClose={() => setSelectedImage(null)}
                disableAutoFocus
                disableEnforceFocus>
                <Box className={classes.modalContent} onClick={() => setSelectedImage(null)}>
                    <IconButton className={classes.closeButton} onClick={(e) => {
                        e.stopPropagation();
                        setSelectedImage(null);
                    }}>
                        <CloseIcon />
                    </IconButton>
                    {selectedImage && (
                        <Box
                            component="img"
                            src={selectedImage}
                            className={classes.modalImage}
                            alt="Selected Screenshot"
                            onClick={(e) => e.stopPropagation()} />
                    )}
                </Box>
            </Modal>
        </Box>
    );
};

export default Screenshots;