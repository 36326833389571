import React from 'react';

import useStyles from '../styles';

const NotFound: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.notFoundContainer}>
            <h1>404 - Page Not Found</h1>
            <p>The specified file was not found on this website.</p>
        </div>
    );
}

export default NotFound;
