import React from 'react';

// Material-UI Components
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

// Localization
import {useTranslation} from 'react-i18next';

// Local styles
import useStyles from '../styles';

const LanguageSwitcher: React.FC = () => {
    const {i18n, t} = useTranslation();
    const [language, setLanguage] = React.useState(i18n.language);
    const classes = useStyles();

    const changeLanguage = async (lng: string) => {
        setLanguage(lng);
        await i18n.changeLanguage(lng);
    };

    return (
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="language-select-label">{t('language')}</InputLabel>
            <Select
                labelId="language-select-label"
                value={language}
                onChange={(e) => changeLanguage(e.target.value as string)}
                label={t('language')}
                className={classes.select}>
                <MenuItem value="en">English</MenuItem>
                <MenuItem value="es">Español</MenuItem>
                <MenuItem value="uk">Українська</MenuItem>
            </Select>
        </FormControl>
    );
};

export default LanguageSwitcher;
