import React from 'react';
import {Container, Typography, Box, Card, CardContent, Link} from '@mui/material';
import {useTranslation} from 'react-i18next';

import useStyles from '../styles';

const Terms: React.FC = () => {
    const classes = useStyles();
    const {t} = useTranslation();

    const termsSections = [
        {title: 'termsAcceptanceOfTerms', content: 'termsAcceptanceOfTermsInfo'},
        {title: 'termsChangesToTerms', content: 'termsChangesToTermsInfo'},
        {title: 'termsUserResponsibilities', content: 'termsUserResponsibilitiesInfo'},
        {title: 'termsIntellectualProperty', content: 'termsIntellectualPropertyInfo'},
        {title: 'termsLimitationOfLiability', content: 'termsLimitationOfLiabilityInfo'},
        {title: 'termsGoverningLaw', content: 'termsGoverningLawInfo'},
        {title: 'termsContactUs', content: 'termsContactUsInfo'},
    ];

    return (
        <Container>
            <Box mt={5} textAlign="center">
                <Typography variant="h2">{t('termsAndConditions')}</Typography>
                <Typography variant="h5" gutterBottom>
                    {t('termsReadTerms')}
                </Typography>
            </Box>
            <Box mt={5}>
                <Container maxWidth="md">
                    {termsSections.map((section, index) => (
                        <Card className={classes.card} key={index}>
                            <CardContent className={classes.cardBody}>
                                <Typography variant="h4">{t(section.title)}</Typography>
                                <Typography>
                                    {section.title === 'termsContactUs' ? (
                                        <>
                                            {t(section.content)}{' '}
                                            <Link href="mailto:fireserdun@gmail.com">fireserdun@gmail.com</Link>.
                                        </>
                                    ) : (
                                        t(section.content)
                                    )}
                                </Typography>
                            </CardContent>
                        </Card>
                    ))}
                </Container>
            </Box>
            <Box className={classes.footer}>
                <Typography variant="h5">{t('contactUs')}</Typography>
                <Typography>
                    {t('termsQuestionsConcerns')}{' '}
                    <Link href="mailto:fireserdun@gmail.com" className={classes.footerLink}>
                        {t('termsContactUsLink')}
                    </Link>.
                </Typography>
                <Typography>&copy; 2024 Alias Game. {t('termsAllRightsReserved')}</Typography>
            </Box>
        </Container>
    );
}

export default Terms;
