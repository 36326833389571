import React from 'react';
import {Container} from '@mui/material';

import DownloadSection from '../components/DownloadSection';
import Features from '../components/Features';
import Jumbotron from '../components/Jumbotron';
import Screenshots from '../components/Screenshots';

const Home: React.FC = () => {
    return (
        <Container>
            <Jumbotron/>
            <Features/>
            <Screenshots/>
            <DownloadSection/>
        </Container>
    );
};

export default Home;
